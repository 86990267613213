<template>
    <div>
        <img class="htopImg vk-bg3"  alt="">
        <section style="background:#FAFAFA;">
            <div class="container" id="padding-top-20">
                    <div class="row justify-content-center">
                    <div class="col-md-5">
                        <div class="styled-heading">
                            <h3>{{$t('sbp')}}</h3>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3" id="step-one">
                        <div class="step-box">
                            <div class="step-number">
                            <span>1</span>
                            <p>{{$t('step')}}</p>
                            </div>
                            <h2>{{$t('sss')}}</h2>
                            <ul>
                                <li>{{$t('includeAll')}}</li>
                                <li>{{$t('includeTrain')}}</li>
                                <!--<li>{{$t('thankFor')}}</li>!-->
                            </ul>
                            <!-- <p>如果您有特殊需要，請聯繫我們</p> -->
                            <i class="arrow-step mobileHide" aria-hidden="true"> ➔</i>
                        </div>
                    </div>
                    
                    <div class="col-xs-12 col-sm-12 col-md-3" id="step-two">
                        <div class="step-box">
                            <div class="step-number">
                            <span>2</span>
                            <p>{{$t('step')}}</p>
                            </div>
                            <h2>{{$t('yourTime')}}</h2>
                            <ul>
                                <li>{{$t('justSI')}}</li>
                                <li>{{$t('youCan5')}}</li>
                                <li>{{$t('beforeMsg')}}</li>
                            </ul>
                            <i class="arrow-step mobileHide" aria-hidden="true"> ➔</i>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3" id="step-three">
                        <div class="step-box">
                            <div class="step-number">
                            <span>3</span>
                            <p>{{$t('step')}}</p>
                            </div>
                            <h2>{{$t('orderPay')}}</h2>
                            <ul>
                                <li>{{$t('cardmsg')}}</li>
                            </ul>
                            <!-- <p>* (如果您需要線下付款，請聯繫我們的客戶服務)<br> </p> -->
                            <i class="arrow-step mobileHide" aria-hidden="true"> ➔</i>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3" id="step-four">
                        <div class="step-box">
                            <div class="step-number">
                            <span>4</span>
                            <p>{{$t('step')}}</p>
                            </div>
                            <h2>{{$t('haveAGoodTrip')}}</h2>
                            <ul>
                                <li>{{$t('ifQueation')}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br>
        <br>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
    .htopImg {
        width: 100%;
        margin: auto;
        display: block;
        height: 33vw;
    }
</style>